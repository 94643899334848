@font-face {
  font-family: "Chesnagrotesk";
  src: url("./fonts/Chesnagrotesk.otf");
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #dbcbef;
  font-family: Chesnagrotesk, sans-serif;
}

#body {
  padding: 10px;
}

header {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

header img {
  width: 70px;
}

header h2 {
  text-transform: uppercase;
  font-weight: 900;
  text-align: center;
  line-height: 1.2;
}

main {
  margin-top: 20px;
}

.mt-40px {
  margin-top: 40px;
}

.mt-10px {
  margin-top: 10px;
}

.component-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.homepage-link {
  width: 100%;
  text-align: center;
  padding: 15px;
  border: 1px solid black;
  border-radius: 10px;
  color: white;
  font-size: 16px;
  background-color: #602e9e;
  text-decoration: none;
}

.start-here__link {
  width: 100%;
  text-align: center;
  padding: 15px;
  border: 1px solid black;
  border-radius: 10px;
  color: white;
  font-size: 16px;
  background-color: #602e9e;
  text-decoration: none;
}

.submit-button {
  width: 100%;
  text-align: center;
  padding: 15px;
  border: 1px solid black;
  border-radius: 10px;
  color: white;
  font-size: 16px;
  background-color: #602e9e;
  text-decoration: none;
}

input {
  border: 1px solid black;
  width: 100% !important;
  font-size: 16px !important;
  padding: 10px;
  border-radius: 10px;
  height: 40px;
}

textarea {
  border: 1px solid black;
  width: 100% !important;
  font-size: 16px !important;
  padding: 10px;
  border-radius: 10px;
}

.journal-entry-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  margin-top: 20px;
}

.journal-entry-form > label {
  font-size: 20px;
  margin-bottom: 3px;
  margin-top: 10px;
}

.error-label {
  color: red;
}

body.keyboard {
  padding-bottom: 500px;
}

.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border-top: 4px solid blue;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
